import crypto from 'crypto';

var dealArray = (arr) => {
  if (arr.length == 0) {
    return "[]";
  }

	let subvalue = "";
	for (var j = 0; j < arr.length; j++) {
		if (arr[j] instanceof Object) {
			subvalue += ',' + dealObject(arr[j]);
		} else {
      subvalue += ',' + arr[j];
		}
	}
	if (subvalue.length > 1) {
		subvalue = subvalue.substring(1);
	}
	return "[" + subvalue + "]";
}

var dealObject = (obj) => {
	var paramStr = '';
	
	if (obj instanceof Object) {
		// 对象 或 数组
		if(isArray(obj)){
			// 数组
			return dealArray(obj);
		}
		// 对象 向下执行
	} else {
		// 既不是对象也不是数组，直接返回
		return obj;
	}
	
	// KEY数组
	var keyArr = [];
	for (var key in obj) {
		if ( typeof(obj[key]) === "undefined" || obj[key] === null) {
			continue;
		}
		let v = obj[key].toString().trim();
		if (v.length > 0) {
			keyArr.push(key);
		}
	}
	// 排序KEY
	keyArr.sort();
	
	
	// 循环KEY，取出VALUE值
	for (var i = 0; i < keyArr.length; i++) {
		let value = obj[keyArr[i]];
		
		if (value instanceof Object) {
			// 对象 或 数组
			if(isArray(value)){
				// 数组
				paramStr += ',' + keyArr[i] + '=' + dealArray(value);
			} else {
				// 对象
				paramStr += ',' + keyArr[i] + '=' + dealObject(value);
			}
		} else {
			paramStr += ',' + keyArr[i] + '=' + value;
		}
	}
	
	if (paramStr.length > 1) {
		paramStr = paramStr.substring(1);
	}
	
	return "{" + paramStr + "}";
}

/**
 * 参数按字典顺序排序
 */
var generateQueryStringByOrder = (param) => {
	var paramStr = '';
	
	// KEY数组
	var keyArr = [];
	for (var key in param) {
		if ( typeof(param[key]) === "undefined" || param[key] === null) {
			continue;
		}
		// let v = param[key].toString().trim();
		// 这样判断不对，因为如果v是一个空数组呢，这样就会漏掉
		/*
		if (v.length > 0) {
			keyArr.push(key);
		}
		*/

		let v = param[key];
		if ( typeof(v) == 'string' ) {
			v = v.toString().trim();
			if (v.length > 0) {
				keyArr.push(key);
			}
		} else {
			keyArr.push(key);
		}
		// console.log('key='+key + ', typeof(v)=' + typeof(param[key]));
	}
	// 排序KEY
	keyArr.sort();
	
	// 循环KEY，取出VALUE值
	for (var i = 0; i < keyArr.length; i++) {
		let value = param[keyArr[i]];
		if (value instanceof Object) {
			// 对象 或 数组
			paramStr += '&' + keyArr[i] + '=' + dealObject(value);
		} else {
			// 普通的值
			paramStr += '&' + keyArr[i] + '=' + value;
		}
	}
	
	if (paramStr.length > 1) {
		paramStr = paramStr.substring(1);
	}
	return paramStr;
};

// MD5加密
var md5encrypt = (param) => {
  var a = '';
  var md5 = crypto.createHash("md5");
  md5.update(param);
  var a = md5.digest('hex');
  return a;
}

/*
* 对Date的扩展，将 Date 转化为指定格式的String
* 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符，
* 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
* 例子：
* (new Date()).format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423
* (new Date()).format("yyyy-M-d h:m:s.S")      ==> 2006-7-2 8:9:4.18
*/
Date.prototype.format = function (fmt) { //author: meizz
  var o = {
    "M+": this.getMonth() + 1, //月份
    "d+": this.getDate(), //日
    "h+": this.getHours(), //小时
    "m+": this.getMinutes(), //分
    "s+": this.getSeconds(), //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    "S": this.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
}

/**
 * aes加密
 * @param data
 * @param secretKey
 */
var aesEncrypt = (data, secretKey) => {
	if (data && secretKey) {
		var cipher = crypto.createCipher('aes-128-ecb', secretKey);
  	return cipher.update(data, 'utf8', 'hex') + cipher.final('hex');
	} else {
		return '';
	}

}

/**
 * aes解密
 * @param data
 * @param secretKey
 * @returns {*}
 */
var aesDecrypt = (data, secretKey) => {
  var cipher = crypto.createDecipher('aes-128-ecb', secretKey);
  return cipher.update(data, 'hex', 'utf8') + cipher.final('utf8');
}

/**
 * 下载导出Excel文件
 * @param url 路径
 * @param params 参数
 */
var downloadExcel = (url, params) => {
  let root = url
  params.timestamps = new Date().format("yyyyMMddhhmmss")
  params.sign = md5encrypt(generateQueryStringByOrder(Object.assign(params)))
  params.token = window.localStorage.getItem('token')
  for (let key in params) {
    root += (key + '=' + params[key] + '&')
  }
  window.open(root)
}

const isArray = (something) => {
  return Object.prototype.toString.call(something) === '[object Array]';
}
const clearAttrs = (obj) => {
  for (let e in obj) {
    let attr = obj[e];
    if (isArray(attr)) {
      obj[e] = [];
    } else {
      obj[e] = null;
    }
  }
  return obj;
}

const objToParam = (a) => {
  let param = "";
  for (let e in a) {
    param += e + "=" + a[e] + "&";
  }
  if (param.length > 1) {
    param = param.substr(0, param.length - 1);
  }
  return param;
}

/**
 * 迭代器类
 * @param {Array} array 
 */
function Iterator(array) {
	this.index = 0;
	this.array = array;
	this.current = undefined;

	if (typeof Iterator._initialized == "undefined") {
		// 是否有下一个元素
		Iterator.prototype.hasNext = function() {
			// console.log('this.index=' + this.index + ', this.array.length=' + this.array.length);
			return this.index < this.array.length;
		};
		// 获取下一个元素
		Iterator.prototype.next = function() {
			this.current = this.array[this.index++];
			return this.current;
		};
		// 重置index
		Iterator.prototype.resetIndex = function() {
			this.index = 0;
		};
		// 通过修改index值来，终止循环
		Iterator.prototype.abort = function() {
			this.index = this.array.length;
		};
		Iterator._initialized = true;
	}
}

/**
 * 循环迭代器的方法
 * @param {Object} it Iterator迭代器
 * @param {Function} each 循环每一个元素的回调函数
 * @param {Number} millisec 间隔时长（毫秒值）。默认0
 * @param {Function} complete 循环结束后的回调函数
 */
function loopIterator(it, each, millisec = 0, complete) {
	if (it.hasNext()) {
		var item = it.next();
		if (each != undefined) {
			each(item);
		}
		setTimeout(function() {
			loopIterator(it, each, millisec, complete);
		}, millisec);
	} else {
		if (complete != undefined) 
			complete();
	}
}

/**
 * 产生随机整数，包含下限值，但不包括上限值
 * @param {Number} lower 下限
 * @param {Number} upper 上限
 * @return {Number} 返回在下限到上限之间的一个随机整数
 */
function random(lower, upper) {
	return Math.floor(Math.random() * (upper - lower)) + lower;
}

export default {
  orderQuery: generateQueryStringByOrder,
  md5encrypt: md5encrypt,
  aesEncrypt: aesEncrypt,
  aesDecrypt: aesDecrypt,
  objToParam: objToParam,
  clearAttrs: clearAttrs,
  downloadExcel: downloadExcel,
  Iterator: Iterator,
  loopIterator: loopIterator,
  random: random
}
