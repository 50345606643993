<template>
    <div class="navbar no-index-bg">
        <div class="navbox">
            <div class="nav active_nav2" v-for="(item, index) in NavList" :key="index" @click="changePath(item, index)">
                <div class="name1" :style="name.indexOf(item.name) > -1  ? 'border-bottom: 3px solid #C50007;' : ''">
                    {{ item.categoryName }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { constants } from '../../common/index';
export default {
    data () {
        return {
            name: '',
            NavList: [
                { categoryName: '首页', name: 'newHome' },
                { categoryName: '货源大厅', name: 'AmpleSupply' },
                { categoryName: '运力大厅', name: 'shippingAbility' },
                { categoryName: '交易资讯', name: 'tradingInformation' },
                { categoryName: '新闻公告', name: 'NewsBulletin' },
                { categoryName: '关于我们', name: 'AboutUs' },
            ], // 导航列表
            cateList: [],
            checkNavList: [],
            currentIndex: 0, // 当前选择导航
            currentId: 0
        }
    },
    watch: {
        currentIdParam () {
            this.load();
        }
    },
    mounted () {
        this.name = this.$route.name
        // this.load();
    },
    methods: {

        /**
         * 打开登录页
         */
        toPC () {
            window.open(constants.BACK_STAGE_URL, '_blank');
        },

        /**
         * 默认开始加载
         */
        load () {
            var menuList = JSON.parse(sessionStorage.getItem('menuList'));
            if (!menuList) {
                this.queryList();
            } else {
                this.NavList = menuList;
            }
            // 校验头部选中效果
            if (this.currentIdParam) {
                this.currentId = this.currentIdParam;
            } else {
                var currentId = sessionStorage.getItem('currentId');
                this.currentId = currentId;
            }
        },

        /**
         * 查询导航栏
         */
        queryList () {
            var params = {
                page: 1,
                limit: 10,
            }
            this.$axios.post('/qx/column/listTree', params).then(res => {
                // console.log(`res = ${JSON.stringify(res)}`);
                if (res.data.code == '200') {
                    var list = res.data.data;
                    if (list.length == 0) {
                        this.$message({
                            message: '未设置菜单错误',
                            type: 'success'
                        });
                        retunr;
                    }
                    // 当前id
                    this.currentId = list[0].id;
                    // 记录首页的位置
                    sessionStorage.setItem('currentId', this.currentId);
                    // console.log(`this.currentId = ${this.currentId}`);
                    var menuList = [];
                    list.forEach(item => {
                        // 只查询以及菜单
                        if (item.pid == 0) {
                            menuList.push(item);
                        }
                    })
                    this.NavList = menuList;
                    sessionStorage.setItem('menuList', JSON.stringify(menuList));
                }
            });
        },

        // 跳转路由
        changePath (item, index) {
            var name = item.name;
            this.$router.push({
                name: name,
            })
        },
        /**
         * 跳转报修平台
         */
        bxptPage () {
            // this.currentIndex = null;
            // sessionStorage.removeItem('currentId');
            this.$router.push({
                path: '/repairPlatform'
            })
        },

    }
}
</script>
<style>
.el-dropdown-menu__item:hover {
    color: #d30000 !important;
}
</style>

<style lang="scss" scoped>
.blue {
    color: #0570c2 !important;
}
.toPC {
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    margin-left: 4px;
}
.navbar {
    height: 68px;
    box-sizing: border-box;
    overflow: hidden;
    z-index: 10;
    position: relative;
    left: 0;
    top: 0;
    width: 100%;

    .navbox {
        height: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        // padding-top: 12px;
        position: relative;
        left: 0;
        top: 0;
        font-family: Microsoft YaHei;
        font-weight: 400;
        .nav {
            display: flex;
            flex: 1;
            color: #ffffff;
            font-size: 16px;
            padding-left: 10px;
            cursor: pointer;
            justify-content: center;
        }
    }
    .active_nav2 {
        flex: 1;
        .name1 {
            line-height: 40px;
            color: #221f1f;
            border-bottom: 3px solid transparent;
            &:hover {
                border-bottom: 3px solid #c50007;
            }
        }
    }
}
</style>