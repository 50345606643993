<template>
<!-- 头部导航公共组件 -->
    <div class="navbar-header">
        <!-- 内容区域 -->
        <div class="container">
          <div class="top-bg-box">
            <div class="left-logo-img">
                <div class="luo-shi">
                    <img src="../../assets/img/logoin.png"/>
                </div>
            </div>
            <!-- 导航栏 -->
            <navbar-list></navbar-list>
          </div>
        </div>
        
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import NavbarList from "./navbarList.vue"
export default {
    data () {
        return {
            date_value: "", // 日期
            weekDay: "", // 星期几
            keyword: "", // 搜索关键字
            userInfo: {},
            isTop: true,
        }
    },
    mounted () {

    },
    computed: {
        ...mapGetters(['token'])
    },
    components: {
        NavbarList
    },
    methods: {
    }
}
</script>

<style lang="scss" scoped>
.navbar-header {
    min-width: 1200px;
    margin: 0 auto;
    background-color: #fff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.18);
    .container {
        min-width: 1200px;
        // padding: 20px 0;
        .top-bg-box {
            width: 1200px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .left-logo-img {
                display: flex;
                align-items: center;
                .luo-shi {
                    margin-right: 24px;
                    width: 300px; // 206
                    height: 32px; // 50px
                    padding-right: 16px;
                    // border-right: 1px solid rgba(255, 255, 255, 0.21);
                }
                .sz-hq {
                    width: 240px;
                    height: 54px;
                }
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
.absolute {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 999;
}
.bag-def {
    background-color: #0363ac;
}
</style>