<template>
    <div class="footerbox">
        <div class="bx info">
            <div class="route_tit">
                <div v-for=" (item,index) in data" :key="index" @click="changePath(item, index)">
                    <span class="route_tit_url">{{ item.categoryName }}</span>
                    <span style="margin: 0 20px;" v-if="(data.length - 1) != index">|</span>
                </div>
            </div>
            <el-divider></el-divider>
            <div class="site">
                <p style="margin-bottom: 10px;">地址:陕西省西安市雁塔区南路中华世纪城(东门)商住8号楼二层 邮编: 710065 电话: 029-89589971 029-89199820</p>
                <p>陕西原化工交易所股份有限公司 版权所有陕ICP备09004887号-4 <img style="margin-left: 20px;vertical-align: top;" src="../../assets/img/beian.png" alt=""> 陕公网安备 61011302000799号</p>
            </div>
        </div>  
    </div>
</template>

<script>
export default {
    data () {
        return {
            data: [
                { categoryName: '首页', name: 'newHome' },
                { categoryName: '货源大厅', name: 'AmpleSupply' },
                { categoryName: '运力大厅', name: 'shippingAbility' },
                { categoryName: '交易资讯', name: 'tradingInformation' },
                { categoryName: '新闻公告', name: 'NewsBulletin' },
                { categoryName: '关于我们', name: 'AboutUs' },
            ]
        }
    },
    mounted () {
    },
    methods: {
        // 跳转路由
        changePath (item, index) {
            var name = item.name;
            this.$router.push({
                name: name,
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.footerbox {
    min-width: 1200px;
    height: 270px;
    box-sizing: border-box;
    background: url("../../assets//img/footer_bg.png") no-repeat; // #D62424
    position: relative;
    left: 0;
    top: 0;
    background-size: 100% 100%;
    .info {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 12px;
        color: #a6aaaf;
        .route_tit {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            .route_tit_url {
                cursor: pointer;
                &:hover {
                    color: #b90511;
                }
            }
        }
        .site {
            width: 100%;
            display: flex;
            flex-direction: column;
            text-align: center;
        }
        /deep/ .el-divider {
            background: #9198a1;
        }
    }
}
</style>